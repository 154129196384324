*,
*::before,
*::after{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
@font-face {
  font-family: 'Fira Sans';
  src: url('../src/fonts/Fira_Sans/FiraSans-Light.ttf');
}
:root{
    --main-font: 'Fira Sans';
    --main-color: #3f9ed8;
    --secondary-color: #a51923;
    --third-color: #686765;
    --main-color-dark:#384046;
    --main-color-light: #ccc9dc;
    --secondary-color-light: #d7e3fc;
    --third-color-light: #e2eafc;
    --fourth-color-light: #edf2fb;
    --gradient-header: linear-gradient(rgba(0, 0, 0, 0.822), rgba(0, 0, 0, 0.726));
}
body {
    font-family: 'Fira Sans'; 
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    overflow-x: hidden;
    overscroll-behavior: none;
    scroll-behavior: smooth;
  }
  a {
    color: var(--main-color);
    text-decoration: none !important;
  }
  a:hover {
    color: var(--third-color);
    text-decoration: underline;
  }
  section {
    padding: 60px 0;
  }
  .section-title {
    text-align: center;
    padding-bottom: 30px;
  }
  .section-title h2 {
    color: var(--secondary-color);
    text-transform:uppercase;
    font-size: 32px;
    font-weight: 600;
    margin-bottom: 20px;
    padding-bottom: 20px;
    position: relative;

    display: grid;
    grid-template-columns: 1fr auto 1fr;
    grid-template-rows: 16px 0;
    grid-gap: 22px;
  }
  .section-title h2::before, .section-title h2::after {
    content: "";
    display: block;
    border-bottom: 2px solid var(--main-color);
  }
  .section-title p {
    margin-bottom: 0;
  }
  /* back-to-top */
  #back-to-top {
    display: none;
    position: fixed;
    bottom: 15px;
    right: 15px;
    z-index: 99;
    font-size: 28px;
    width: 40px;
    height: 40px;
    border: none;
    outline: none;
    background-color: var(--main-color);
    border: 1px solid var(--fourth-color-light);
    cursor: pointer;
    transition: all 0.7s;
  }
  #back-to-top i{
    color: var(--fourth-color-light);
    line-height: 0;
  }
  #back-to-top:hover {
    background-color: var(--main-color-light);
    color: var(--main-color);
  }
  #back-to-top i:hover{
    animation: up-down 1s ease-in-out infinite alternate-reverse both;
  }
  /* header */
  #header {
    height: 70px;
    transition: all 0.5s;
    z-index: 997;
    transition: all 0.5s;
    background: var(--fourth-color-light);
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.329);
  }
  #header .logo h1 {
    font-size: 28px;
    margin: 0;
    font-weight: 600;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: var(--main-color-dark);
  }
  #header .logo h1 a, #header .logo h1 a:hover {
    color: var(--main-color-dark);
    text-decoration: none;
  }
  #header .logo img {
    max-height: 50px;
  }
  /* navbar */
  .navbar {
    padding: 0;
  }
  .navbar ul {
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;
    align-items: center;
  }
  .navbar li {
    position: relative;
  }
  .navbar a, .navbar a:focus {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0 10px 30px;
    font-size: 16px;
    font-weight: 500;
    color: var(--secondary-color);
    white-space: nowrap;
    transition: 0.3s;
  }
  .navbar a i, .navbar a:focus i {
    font-size: 12px;
    line-height: 0;
    margin-left: 5px;
  }
  .navbar a:hover, .navbar .active, .navbar .active:focus, .navbar li:hover > a {
    color: var(--main-color);
  }
  /* hero */
  #hero {
    width: 100%;
    height: 100%;
    min-height: 70vh;
    background: var(--gradient-header), url("../src/images/main_img.webp") center center;
    background-size: cover;
    position: relative;
    margin-top: 70px;
    padding: 0;
  }
  #hero:before {
    content: "";
    background: rgba(56, 64, 70, 0.7);
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
  }
  #hero .container {
    z-index: 2;
  }
  #hero img{
      width: 60%;
      height: auto;
      margin: 0 0 10px 0;
  }
  #hero .btn-get-started {
    font-weight: 400;
    font-size: 13px;
    letter-spacing: 2px;
    display: inline-block;
    padding: 12px 28px;
    margin: 15px;
    border-radius: 4px;
    transition: ease-in-out 0.3s;
    text-decoration: none;
    color: var(--secondary-color-light);
    background: var(--secondary-color);
    text-transform: uppercase;
  }
  #hero .btn-get-started:hover {
    background: var(--main-color);
  }
  @media (max-width: 992px) {
    #hero {
      height: calc(100vh - 70px);
    }
  }
  @media (max-width: 768px) {
    #hero h1 {
      font-size: 30px;
      line-height: 36px;
    }
    #hero h2 {
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 30px;
    }
  }
  /* home */
  #home{
      background-color: var(--fourth-color-light);
  }
  .home .icon-box {
    text-align: center;
    margin-top: 20px;
    padding: 30px 20px;
    transition: all ease-in-out 0.3s;
    background: var(--fourth-color-light);
    border: 2px solid var(--main-color);
  }
  .home .icon-box .icon {
    margin: 0 auto;
    width: 64px;
    height: 64px;
    background: var(--fourth-color-light);
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    transition: ease-in-out 0.3s;
  }
  .home .icon-box .icon i {
    color: var(--main-color);
    font-size: 28px;
  }
  .home .icon-box h3 {
    font-weight: 700;
    margin-bottom: 15px;
    font-size: 24px;
    color: var(--main-color-dark);
    transition: ease-in-out 0.3s;
  }
  .home .icon-box p {
    line-height: 24px;
    font-size: 14px;
    margin-bottom: 0;
  }
  .home .icon-box:hover {
    border-color: #fff;
    background-color: #fff;
    box-shadow: 0px 0 25px 0 rgba(0, 0, 0, 0.1);
  }
  .home .icon-box:hover h3, .what-we-do .icon-box:hover .icon i {
    color: var(--main-color);
  }
  /* about */
  .about {
    padding: 60px 0;
    background-color: var(--third-color-light);
  }
  .about img{
    width: auto;
    height: auto;
  }
  .about h3 {
    font-weight: 600;
    font-size: 32px;
  }
  .about ul {
    list-style: none;
    padding: 0;
    font-size: 15px;
  }
  .about ul li + li {
    margin-top: 10px;
  }
  .about ul li {
    position: relative;
    padding-left: 26px;
  }
  .about ul i {
    position: absolute;
    left: 0;
    top: 20px;
    margin-right: 10px;
    font-size: 22px;
    color: var(--secondary-color);
  }
 
  /* us */
  .team{
    background-color: var(--third-color-light);
  }
  .team .member {
    text-align: center;
    margin-bottom: 20px;
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.1);
    padding: 30px 20px;
    background: #fff;
  }
  .team .member img {
    height: 4vh;
    border-radius: 50%;
    margin: 0 0 30px 0;
  }
  .team .member h1 {
    font-weight: 700;
    margin-bottom: 2px;
    font-size: 18px;
    color: var(--secondary-color);
  }
  .team .member span {
    font-style: italic;
    display: block;
    font-size: 13px;
  }
  .team .member p {
    padding-top: 10px;
    font-size: 14px;
    font-style: italic;
    color: var(--third-color);
  }
  .team .member .social {
    margin-top: 15px;
  }
  .team .member .social a {
    color: var(--third-color);
    transition: 0.3s;
    text-decoration: none;
  }
  .team .member .social a:hover {
    color: var(--main-color);
  }
  .team .member .social i {
    font-size: 18px;
    margin: 0 2px;
  }
  /* service section */
  .services{
    background-color: var(--fourth-color-light);
  }
  .services .icon-box {
    padding: 30px;
    height: 100%;
    border-radius: 6px;
    background: #fff;
    transition: ease-in-out 0.3s;
    box-shadow: 0px 2px 22px rgba(0, 0, 0, 0.08);
    border:1px solid #fff;
  }
  .services .icon-box i {
    padding-top:25px ;
    float: left;
    color: var(--main-color);
    font-size: 40px;
    line-height: 0;
  }
  .services .icon-box h2 {
    margin-left: 70px;
    font-weight: 700;
    margin-bottom: 15px;
    font-size: 18px;
  }
  .services .icon-box p {
    margin-left: 70px;
    line-height: 24px;
    font-size: 14px;
  }
  .services .icon-box:hover {
    border:1px solid var(--main-color);
  }
  .services .icon-box:hover h2 {
    color: var(--main-color);
  }
  /* contact section */
  .contact .info-wrap {
    padding: 30px;
    background: #fff;
    border-radius: 4px;
  }
  .contact .info i {
    font-size: 20px;
    color: var(--main-color);
    float: left;
    width: 44px;
    height: 44px;
    background: var(--fourth-color-light);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    transition: all 0.3s ease-in-out;
  }
  .contact .info h3,h4 {
    padding: 0 0 0 60px;
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 5px;
    color: var(--main-color-dark);
  }
  .contact .info p {
    padding: 0 0 0 60px;
    margin-bottom: 0;
    font-size: 14px;
    color: var(--secondary-color);
  }
  .contact .info:hover i {
    background: var(--main-color);
    color: #fff;
  }
  /* footer */
  #footer{
      background-color: var(--fourth-color-light);
  }
  #footer a{
      color: var(--secondary-color);
  }
  #footer .footer-links{
      padding: 8px;
      color: var(--main-color-dark);
      text-decoration: none;
  }
  #footer .credits {
    padding-top: 5px;
    font-size: 13px;
    color: var(--main-color-dark);
  }
  #footer .credits img {
    height: 130px;
    width: auto;
  }
  #footer .social-links a:hover {
    background: var(--main-color-dark);
    color: #fff;
    text-decoration: none;
  }